import { CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import { lazy, Suspense } from 'react';
import { positions, Provider as AlertProvider } from 'react-alert';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AnchorProvider from './components/AnchorProvider';
import AppTemplate from './components/AppTemplate';
import { DialogProvider } from './components/Dialog';
import Footer from './components/Footer';
import InstanceProvider from './components/InstanceProvider';
import NavigationHeader from './components/NavigationHeader/NavigationHeader';
import PrivateRoute from './components/PrivateRoute';
import theme from './components/Theme';
import WaxProvider from './components/WaxProvider';
import Login from './pages/Login';
import AlertTemplate from './providers/AlertTemplate';
import ContextProvider from './providers/Context';
import ControlSessionTime from './providers/ControlSessionTime';
import IntlProviderConfigured from './providers/IntlProviderConfigured';
import RestoreAnchorLogin from './providers/RestoreAnchorLogin';
import RestoreWaxLogin from './providers/RestoreWaxLogin';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const TWO_SECONDS_AND_HALF = 2500;

const App = () => (
  <Suspense fallback={<CircularProgress />}>
    <QueryClientProvider client={queryClient}>
      <IntlProviderConfigured>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AlertProvider
              template={AlertTemplate}
              position={positions.TOP_CENTER}
              timeout={TWO_SECONDS_AND_HALF}
            >
              <WaxProvider>
                <AnchorProvider>
                  <RestoreAnchorLogin />
                  <RestoreWaxLogin>
                    <InstanceProvider>
                      <DialogProvider>
                        <ControlSessionTime />
                        <NavigationHeader />
                        <Routes>
                          <Route path="/" element={<Login />} />
                          <Route
                            path="/*"
                            element={
                              <PrivateRoute>
                                <ContextProvider>
                                  <AppTemplate />
                                </ContextProvider>
                              </PrivateRoute>
                            }
                          />
                        </Routes>
                      </DialogProvider>
                    </InstanceProvider>
                  </RestoreWaxLogin>
                </AnchorProvider>
              </WaxProvider>
              <Footer />
            </AlertProvider>
          </ThemeProvider>
        </BrowserRouter>
      </IntlProviderConfigured>
    </QueryClientProvider>
  </Suspense>
);

export default App;
