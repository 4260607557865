import { Components, Theme } from '@mui/material';

const makeMuiCard = (theme: Theme): Components['MuiCard'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.grey[100],
      backgroundColor: theme.palette.grey[500],
      borderColor: theme.palette.success.main,
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
    },
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        backgroundColor: theme.palette.grey[500],
        borderColor: theme.palette.grey[500],
        borderRadius: theme.shape.borderRadius,
      },
    },
  ],
});

export default makeMuiCard;
