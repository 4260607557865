import { Components, Theme } from '@mui/material';

const makeMuiButton = (theme: Theme): Components['MuiButton'] => ({
  defaultProps: {
    color: 'primary',
    disableElevation: true,
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      borderRadius: +theme.shape.borderRadius,
      color: theme.palette.grey[100],
      fontSize: 16,
      fontWeight: 600,
      height: 50,
      textTransform: 'none',
    },
    sizeSmall: { fontSize: 12 },
    sizeLarge: { fontSize: 16 },
  },
  variants: [
    {
      props: { color: 'primary', variant: 'contained' },
      style: {
        background: `linear-gradient(0deg, ${theme.palette.grey[600]} 0%, ${theme.palette.grey[400]} 100%)`,
        borderColor: theme.palette.success.main,
        borderRadius: theme.shape.borderRadius,
        borderStyle: 'solid',
        borderWidth: 1,
      },
    },
    {
      props: { color: 'primary', variant: 'text' },
      style: {
        background: 'none',
        border: 'none',
        '&:hover': {
          background: 'none',
          color: theme.palette.success.main,
        },
      },
    },
    {
      props: { color: 'success', variant: 'contained' },
      style: {
        background: theme.palette.success.main,
        color: theme.palette.grey[100],
      },
    },
    {
      props: { color: 'secondary', variant: 'contained' },
      style: {
        background: theme.palette.secondary.main,
        color: theme.palette.grey[100],
      },
    },
    {
      props: { color: 'info', variant: 'contained' },
      style: {
        background: theme.palette.info.main,
        color: theme.palette.grey[100],
      },
    },
    {
      props: { color: 'success', variant: 'outlined' },
      style: {
        background: 'none',
        borderColor: theme.palette.success.main,
        borderStyle: 'solid',
        borderWidth: 1,
        '&:hover': {
          background: theme.palette.success.main,
        },
      },
    },
    {
      props: { color: 'error', variant: 'outlined' },
      style: {
        background: `linear-gradient(${theme.palette.grey[600]}, ${theme.palette.error.dark})`,
        borderColor: theme.palette.error.dark,
        borderStyle: 'solid',
        borderWidth: 1,
        '&:hover': {
          borderColor: theme.palette.error.dark,
        },
      },
    },
    {
      props: { size: 'small' },
      style: {
        height: +theme.shape.sizes.small,
      },
    },
    {
      props: { size: 'medium' },
      style: {
        height: +theme.shape.sizes.medium,
      },
    },
    {
      props: { size: 'large' },
      style: {
        height: +theme.shape.sizes.large,
      },
    },
  ],
});

export default makeMuiButton;
