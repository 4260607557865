import { CircularProgress } from '@mui/material';
import { WaxJS } from '@waxio/waxjs/dist';
import {
  createContext,
  ReactChild,
  ReactChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { getPoolsInfo } from '../services/getPoolsInfo';
import { CoinType } from '../types/Coin';
import { Pool } from '../types/Pool';
import { Token } from '../types/Token';
import { WaxContext } from '../components/WaxProvider';

type PoolsInfo = {
  poolsInfo: Pool[];
  tokens: Token[];
};

type ContextProps = {
  pools: PoolsInfo;
  tokens: Token[];
  wax: WaxJS;
  setTokens: (tokens: Token[]) => void;
  refetch: () => void;
};

type ContextProviderProps = {
  children: ReactChild | ReactChildren;
};

export const Context = createContext({} as ContextProps);

const ContextProvider = ({ children }: ContextProviderProps) => {
  const { wax } = useContext(WaxContext);
  const userName = localStorage.getItem('wax_userAccount') as string;
  const [tokens, setTokens] = useState([
    {
      symbol: CoinType.ESB,
      amount: 0,
    },
    {
      symbol: CoinType.WAX,
      amount: 0,
    },
  ]);
  const { isLoading, data, refetch } = useQuery('getPoolsInfo', () =>
    getPoolsInfo(userName),
  );
  useEffect(() => {
    const containsTokens = data?.data.tokens.length;
    if (containsTokens) {
      const filteredTokens = data?.data.tokens.filter((token: { symbol: CoinType; contract: string; }) => 
        (token.symbol === CoinType.WAX) || 
        (token.symbol === CoinType.ESB && token.contract === "essentianova")
      );
      setTokens(filteredTokens);
    }
  }, [data]);
  
  if (isLoading) return <CircularProgress />;
  return (
    <Context.Provider
      value={{
        pools: data?.data,
        setTokens,
        tokens,
        refetch,
        wax,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
