import { Components, Theme } from '@mui/material';

const makeMuiCssBaseline = (theme: Theme): Components['MuiCssBaseline'] => ({
  styleOverrides: {
    body: {
      backgroundColor: theme.palette.grey[600],
    },
    a: {
      textDecoration: 'none',
    },
  },
});

export default makeMuiCssBaseline;
