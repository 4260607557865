import { createContext, ReactChild, ReactChildren, useState } from 'react';
import AnchorLink, { LinkSession } from 'anchor-link';
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport';

const ANCHOR_CHAIN_ID =
  '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4';
const ANCHOR_NODE_URL = 'https://wax.greymass.com';

const transport = new AnchorLinkBrowserTransport();
const link = new AnchorLink({
  transport,
  chains: [
    {
      chainId: ANCHOR_CHAIN_ID,
      nodeUrl: ANCHOR_NODE_URL,
    },
  ],
});

type ContextProps = {
  anchor: AnchorLink;
  anchorInstance: LinkSession;
  setAnchorInstance: (anchorInstance: LinkSession) => void;
};

export const AnchorContext = createContext({} as ContextProps);

const AnchorProviderContext = ({
  children,
}: {
  children: ReactChild | ReactChildren | ReactChild[] | ReactChildren[];
}) => {
  const [anchorInstance, setAnchorInstance] = useState<LinkSession>(
    {} as LinkSession,
  );
  return (
    <AnchorContext.Provider
      value={{ anchor: link, anchorInstance, setAnchorInstance }}
    >
      {children}
    </AnchorContext.Provider>
  );
};

export default AnchorProviderContext;
