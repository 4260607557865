import { Avatar, Stack, SxProps, Theme, Typography } from '@mui/material';
import { LinkSession, LoginResult } from 'anchor-link';
import { useContext } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import waxCloudLogo from '../../../assets/png/waxCloudLogo.png';
import anchorLogo from '../../../assets/svg/anchorLogo.svg';
import { AnchorContext } from '../../../components/AnchorProvider';
import { useDialog } from '../../../components/Dialog';
import { WaxContext } from '../../../components/WaxProvider';
import { IntlContext } from '../../../providers/IntlProviderConfigured';
import { addSessionTime } from '../../../services/sessionTime';
import translations from '../../../_i18n/login.json';

interface ButtonLoginProps {
  title: string;
  logoButton: string;
  sx?: SxProps<Theme>;
  onClickButton?: () => void;
}

const ButtonLogin = ({
  title,
  sx,
  logoButton,
  onClickButton,
}: ButtonLoginProps) => (
  <Stack
    alignItems="center"
    direction="row"
    spacing={3}
    sx={{
      borderRadius: 1,
      cursor: 'pointer',
      px: 1,
      py: 2,
      width: '100%',
      ...sx,
    }}
    onClick={onClickButton}
  >
    <Avatar src={logoButton} sx={{ height: 40, width: 40 }} />
    <Typography variant="subtitle1" sx={{ color: 'grey.100', fontWeight: 600 }}>
      {title}
    </Typography>
  </Stack>
);

const ANCHOR_APP = 'Breeders';

type ChooseLoginPlataformProps = {
  onError: () => void;
  onSuccess: (session?: LinkSession) => void;
};

const ChooseLoginPlataform = ({
  onError,
  onSuccess,
}: ChooseLoginPlataformProps) => {
  const dialog = useDialog();
  const { language } = useContext(IntlContext);
  const { saveCredentialsInLocalStorage, wax } = useContext(WaxContext);
  const { anchor, setAnchorInstance } = useContext(AnchorContext);
  const loginWithWax = async () => {
    const validCredentials = await wax.isAutoLoginAvailable();
    const timeNow = new Date().getTime();
    addSessionTime(timeNow);
    localStorage.setItem('platformType', 'WAX');
    if (validCredentials) {
      saveCredentialsInLocalStorage();
      dialog.close();
      onSuccess();
      return;
    }
    try {
      await wax.login();
      saveCredentialsInLocalStorage();
      onSuccess();
    } catch {
      onError();
    } finally {
      dialog.close();
    }
  };
  const loginWithAnchor = async () => {
    localStorage.setItem('platformType', 'ANCHOR');
    const timeNow = new Date().getTime();
    addSessionTime(timeNow);
    try {
      const thereIsSession = await anchor.restoreSession(ANCHOR_APP);
      if (thereIsSession) {
        setAnchorInstance(thereIsSession);
        localStorage.setItem('wax_userAccount', `${thereIsSession.auth.actor}`);
        onSuccess(thereIsSession);
        return;
      }
      const loginResult: LoginResult = (await anchor.login(
        ANCHOR_APP,
      )) as LoginResult;
      setAnchorInstance(loginResult.session);
      localStorage.setItem('wax_userAccount', loginResult.payload.sa);
      onSuccess(loginResult.session);
    } catch (err) {
      onError();
    } finally {
      dialog.close();
    }
  };
  return (
    <>
      <IntlProvider
        locale={language}
        key={language}
        messages={translations[language]}
      >
        <Typography variant="h1" sx={{ color: 'grey.500', fontWeight: 600 }}>
          <FormattedMessage id="ChooseOptionToContinue" />
        </Typography>
        <ButtonLogin
          title="WAX Cloud Wallet"
          logoButton={waxCloudLogo}
          sx={{ bgcolor: 'grey.600', mt: 8 }}
          onClickButton={loginWithWax}
        />
        <ButtonLogin
          title="Anchor"
          logoButton={anchorLogo}
          sx={{ bgcolor: 'info.main', mt: 4 }}
          onClickButton={loginWithAnchor}
        />
      </IntlProvider>
    </>
  );
};

export default ChooseLoginPlataform;
