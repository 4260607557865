import { Box } from '@mui/material';

type HamburgerMenuProps = {
  open: boolean;
  onClick: () => void;
};

const HamburgerMenu = ({ open, onClick }: HamburgerMenuProps) => (
  <Box
    onClick={onClick}
    sx={{
      display: { desktop: 'none' },
      backgroundColor: open ? 'rgba(255, 255, 255, 0)' : 'grey.100',
      borderRadius: 1,
      height: 5,
      position: 'absolute',
      right: open ? '35%' : '20px',
      transition: 'transform 400ms cubic-bezier(0.23, 1, 0.32, 1)',
      width: 35,
      '&:before': {
        backgroundColor: 'grey.100',
        borderRadius: 1,
        content: '""',
        display: { desktop: 'none' },
        height: 5,
        mt: open ? 0 : -2,
        position: 'absolute',
        transform: open ? 'rotate(405deg)' : 'rotate(0deg)',
        transition: 'transform 400ms cubic-bezier(0.23, 1, 0.32, 1)',
        width: 35,
      },
      '&:after': {
        backgroundColor: 'grey.100',
        borderRadius: 1,
        content: '""',
        display: { desktop: 'none' },
        height: 5,
        mt: open ? 0 : 2,
        position: 'absolute',
        transform: open ? 'rotate(-405deg)' : 'rotate(0deg)',
        transition: 'transform 400ms cubic-bezier(0.23, 1, 0.32, 1)',
        width: 35,
      },
    }}
  />
);

export default HamburgerMenu;
