import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import welcomeBackground from '../../assets/jpg/welcomeBackground.jpg';
import { useDialog } from '../../components/Dialog';
import ChooseLoginPlataform from './components/ChooseLoginPlataform';

const Login = () => {
  const dialog = useDialog();
  const navigate = useNavigate();
  const onLoginSuccess = () => {
    navigate('/stake/NFT');
  };
  const onLoginError = () => {
    navigate('/');
  };
  useEffect(() => {
    const handleClickOpen = () => {
      dialog.open({
        element: (
          <ChooseLoginPlataform
            onError={onLoginError}
            onSuccess={onLoginSuccess}
          />
        ),
        closable: false,
        hideCloseIcon: true,
        dialogProps: { sx: { zIndex: 1 } },
        sx: { width: { mobile: '100%', desktop: 416 }, height: 390 },
      });
    };
    handleClickOpen();
  }, []);
  return (
    <Box
      sx={{
        backgroundImage: `url(${welcomeBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        minHeight: '100vh',
      }}
    />
  );
};

export default Login;
