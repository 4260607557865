import { Components, Theme } from '@mui/material';

const makeMuiLoadingButton = (
  theme: Theme,
): Components['MuiLoadingButton'] => ({
  defaultProps: {
    loadingPosition: 'start',
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        backgroundColor: `${theme.palette.success.dark} !important`,
        color: `${theme.palette.grey[100]} !important`,
      },
      '&:hover': { background: theme.palette.primary.main },
    },
    loadingIndicator: {
      position: 'static',
    },
    loadingIndicatorStart: {
      marginRight: theme.spacing(2),
    },
    loadingIndicatorEnd: {
      marginLeft: theme.spacing(2),
    },
  },
  variants: [
    {
      props: { color: 'success', variant: 'contained' },
      style: {
        background: theme.palette.success.main,
        color: theme.palette.grey[100],
      },
    },
    {
      props: { color: 'secondary', variant: 'contained' },
      style: {
        background: theme.palette.secondary.main,
        color: theme.palette.grey[100],
      },
    },
    {
      props: { color: 'info', variant: 'contained' },
      style: {
        background: theme.palette.info.main,
        color: theme.palette.grey[100],
      },
    },
    {
      props: { size: 'small' },
      style: {
        height: +theme.shape.sizes.small,
      },
    },
    {
      props: { size: 'medium' },
      style: {
        height: +theme.shape.sizes.medium,
      },
    },
    {
      props: { size: 'large' },
      style: {
        height: +theme.shape.sizes.large,
      },
    },
  ],
});

export default makeMuiLoadingButton;
