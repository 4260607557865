import { Components, Theme } from '@mui/material';

const makeMuiSvgIcon = (theme: Theme): Components['MuiSvgIcon'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.primary.main,
    },
  },
  variants: [
    {
      props: { fontSize: 'large' },
      style: {
        fontWeight: 600,
        [theme.breakpoints.up('mobile')]: {
          fontSize: '20px !important',
        },
        [theme.breakpoints.up('desktop')]: {
          fontSize: '26px !important',
        },
      },
    },
  ],
});

export default makeMuiSvgIcon;
