import { Components, Theme } from '@mui/material';

const makeMuiMenu = (theme: Theme): Components['MuiMenu'] => ({
  defaultProps: { variant: 'selectedMenu' },
  styleOverrides: {
    paper: {
      backgroundColor: `${theme.palette.grey[500]} !important`,
    },
  },
  variants: [
    {
      props: { variant: 'selectedMenu' },
      style: {
        '.MuiMenu-list': {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
    },
  ],
});

export default makeMuiMenu;
