import { CircularProgress } from '@mui/material';
import {
  ReactChild,
  ReactChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WaxContext } from '../components/WaxProvider';

const RestoreWaxLogin = ({
  children,
}: {
  children: ReactChild | ReactChildren;
}) => {
  const { saveCredentialsInLocalStorage, wax } = useContext(WaxContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isOnTheMarketPageOrBox =
    pathname.includes('/breeders/box') || pathname.includes('/breeders/market');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const checkWaxCredentials = async () => {
      const platformType = localStorage.getItem('platformType') as string;
      const platformIsTypeWax = platformType === 'WAX';
      if (platformIsTypeWax) {
        setIsLoading(true);
        const validCredentials = await wax.isAutoLoginAvailable();
        if (validCredentials) {
          saveCredentialsInLocalStorage();
          setIsLoading(false);
          return;
        }
        try {
          setIsLoading(true);
          await wax.login();
          saveCredentialsInLocalStorage();
        } catch {
          navigate('/');
        } finally {
          setIsLoading(false);
        }
      }
    };
    if (!isOnTheMarketPageOrBox) {
      checkWaxCredentials();
    }
  }, [pathname]);
  if (isLoading) return <CircularProgress />;
  return <>{children}</>;
};

export default RestoreWaxLogin;
