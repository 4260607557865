/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactChild, ReactChildren, useState } from 'react';
import LANGUAGES from '../_i18n/languages';

export type LanguageType = 'en' | 'pt';

type ContextProps = {
  language: LanguageType;
  setLanguage: (lang: LanguageType) => void;
};

type IntlProviderConfiguredProps = {
  children: ReactChild | ReactChildren;
};

export const IntlContext = createContext({} as ContextProps);

const IntlProviderConfigured = ({ children }: IntlProviderConfiguredProps) => {
  const restoreLastLanguage = localStorage.getItem('lang') as any;
  const [language, setLanguage] = useState<any>(
    restoreLastLanguage || LANGUAGES.default,
  );
  return (
    <IntlContext.Provider value={{ language, setLanguage }}>
      {children}
    </IntlContext.Provider>
  );
};

export default IntlProviderConfigured;
