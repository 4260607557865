import { Components, Theme } from '@mui/material';

const makeMuiMenuItem = (theme: Theme): Components['MuiMenuItem'] => ({
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.grey[400],
      borderRadius: +theme.shape.borderRadius,
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginTop: theme.spacing(1),
      ':hover':{
        backgroundColor: theme.palette.grey[300],
      }
    },
  },
});

export default makeMuiMenuItem;
