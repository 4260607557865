import { CircularProgress, Container } from '@mui/material';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './Header';

const HomeContainer = lazy(() => import('./HomeContainer'));

const AppTemplate = () => (
  <Suspense fallback={<CircularProgress />}>
    <Container sx={{ maxWidth: 1200, pt: 5, width: '100%' }}>
      <Header />
      <Routes>
        <Route path="/stake/*" element={<HomeContainer />} />
      </Routes>
    </Container>
  </Suspense>
);

export default AppTemplate;
