import axios from 'axios';

const axiosHelper = axios.create({});

axiosHelper.interceptors.response.use(
  (config) => config,
  (error) => console.log(error),
);

export default axiosHelper;
