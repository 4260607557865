import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useDialog } from '../../Dialog';

export type LinkType = {
  label: string;
  href: string;
  external: boolean;
};

const links: LinkType[] = [
  {
    label: 'Home',
    href: 'http://breeders.zone',
    external: true,
  },
  {
    label: 'Stake',
    href: 'https://stake.breeders.zone/stake/NFT',
    external: true,
  },
  {
    label: 'Exchange',
    href: 'https://exchange.breeders.zone',
    external: true,
  },
];

type LinksListProps = {
  sx?: SxProps<Theme>;
};

const LinksList = ({ sx }: LinksListProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dialog = useDialog();
  const openPagesByLink = (url: LinkType) => {
    if (url.external) {
      window.open(url.href, '_blank');
      return;
    }
    dialog.close();
    navigate(url.href);
  };
  
  const linkIsSelected = (url: LinkType) => {
    const stakeIsSelected =
      matchPath('/stake/*', pathname) && url.href === '/' && !url.external;
    if (stakeIsSelected) return true;
    return pathname === url.href && !url.external;
  };
  return (
    <Stack
      alignItems={{ mobile: 'flex-start', desktop: 'center' }}
      direction={{ mobile: 'column', desktop: 'row' }}
      sx={sx}
    >
      {links.map((url, index) => (
        <Stack
          key={url.label}
          alignItems="center"
          direction="row"
          sx={{ cursor: 'pointer' }}
          onClick={() => openPagesByLink(url)}
        >
          <Typography
            sx={{
              color: linkIsSelected(url) ? 'primary.main' : 'grey.100',
              fontSize: 16,
              mb: { mobile: 3, desktop: 0 },
              mx: { mobile: 0, desktop: 3 },
              textTransform: 'uppercase',
              '&:hover': { color: 'primary.main' },
            }}
          >
            <FormattedMessage id={url.label} />
          </Typography>
          {index < links.length - 1 && (
            <Typography
              sx={{
                color: 'grey.100',
                display: { mobile: 'none', desktop: 'block' },
                fontSize: 22,
              }}
            >
              |
            </Typography>
          )}
        </Stack>
      ))}
    </Stack>
  );
  
};

export default LinksList;
