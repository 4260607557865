import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { RefObject, useContext, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import translations from '../../_i18n/footer.json';
import breedersLogo from '../../assets/png/breedersLogo.png';
import discordIcon from '../../assets/png/discordIcon.png';
import emailIcon from '../../assets/png/emailIcon.png';
import instagramIcon from '../../assets/png/instagramIcon.png';
import mediumIcon from '../../assets/png/mediumIcon.png';
import telegramIcon from '../../assets/png/telegramIcon.png';
import twitterIcon from '../../assets/png/twitterIcon.png';
import youtubeIcon from '../../assets/png/youtubeIcon.png';
import { IntlContext } from '../../providers/IntlProviderConfigured';
import sendEmail from './services/sendEmailToReceiveNews';

type ExternalUrl = {
  href: string;
  label: string;
  external: boolean;
};

const externalUrls: ExternalUrl[] = [
  {
    label: 'Home',
    href: 'breeders.zone',
    external: true,
  },
  {
    label: 'Stake',
    href: '/',
    external: false,
  },
  {
    label: 'Market',
    href: '/breeders/market',
    external: false,
  },
  {
    label: 'Breeders Box',
    href: '/breeders/box',
    external: false,
  },
  {
    label: 'Tokenomics',
    href: '/tokenomics/',
    external: true,
  },
  {
    label: 'Story',
    href: '/story/',
    external: true,
  },
  {
    label: 'About us',
    href: '/about-us/',
    external: true,
  },
];

const socialNetworks = [
  {
    href: 'https://twitter.com/breeders_zone',
    label: 'Twitter',
    thumbnail: twitterIcon,
  },
  {
    href: 'https://www.instagram.com/breeders_zone/',
    label: 'Instagram',
    thumbnail: instagramIcon,
  },
  {
    href: 'https://discord.gg/rQvyneXu4C',
    label: 'Discord',
    thumbnail: discordIcon,
  },
  {
    href: 'https://www.youtube.com/channel/UCKC3t5D3jrA6to_dSbwHzog',
    label: 'Youtube',
    thumbnail: youtubeIcon,
  },
  {
    href: 'https://t.me/breeders_zone',
    label: 'Telegram',
    thumbnail: telegramIcon,
  },
  {
    href: 'https://breeders-zone.medium.com/',
    label: 'Medium',
    thumbnail: mediumIcon,
  },
  {
    href: 'mailto:contact@breeders.zone',
    label: 'Email',
    thumbnail: emailIcon,
  },
];

const Footer = () => {
  const alert = useAlert();
  const { language } = useContext(IntlContext);
  const { pathname } = useLocation();
  const emailInput: RefObject<HTMLInputElement> = useRef(null);
  const navigate = useNavigate();
  const [sendingEmail, setSendingEmail] = useState(false);
  const openPagesByLink = (url: ExternalUrl) => {
    if (url.external) {
      window.location.replace(
        `${process.env.REACT_APP_BREEDERS_HOSTNAME}${url.href}`,
      );
      return;
    }
    navigate(url.href);
  };
  const verifyLinkIsSelected = (url: ExternalUrl) => {
    const stakeIsSelected =
      pathname.includes('/stake') && url.href === '/' && !url.external;
    if (stakeIsSelected) return true;
    return pathname === url.href && !url.external;
  };
  const resetInputValue = (input: HTMLInputElement) => (input.value = '');
  const sendEmailToReceiveNews = async () => {
    if (emailInput?.current) {
      const input = emailInput.current.querySelector(
        'input',
      ) as HTMLInputElement;
      const { value } = input;
      if (value) {
        setSendingEmail(true);
        try {
          const { data } = await sendEmail(value);
          const { message } = data;
          alert.success(message.message);
          resetInputValue(input);
        } catch {
          alert.error('There was an error sending your email.');
        } finally {
          setSendingEmail(false);
        }
      }
    }
  };
  return (
    <IntlProvider
      locale={language}
      key={language}
      messages={translations[language]}
    >
      <Card
        variant="outlined"
        data-testid="footer"
        sx={{ borderRadius: 0, height: { mobile: 'auto', desktop: 317 } }}
      >
        <CardContent sx={{ height: '85%' }}>
          <Stack
            alignItems="center"
            direction={{ mobile: 'column', desktop: 'row' }}
            justifyContent="space-between"
            spacing={{ mobile: 6, desktop: 0 }}
            sx={{
              height: '100%',
              maxWidth: 1200,
              margin: ' auto',
            }}
          >
            <Avatar src={breedersLogo} sx={{ height: 'auto', width: 'auto', margin: 'auto', }} />
          </Stack>
        </CardContent>
        <CardContent sx={{ bgcolor: 'grey.400', height: '15%', mb: 0 }}>
          <Typography
            sx={{ color: 'grey.200', fontSize: 14, textAlign: 'center' }}
          >
            Copyright © 2024 Breeders Zone | All Rights Reserved
          </Typography>
        </CardContent>
      </Card>
    </IntlProvider>
  );
};

export default Footer;
