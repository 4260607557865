import { Components, Theme } from '@mui/material';

const makeMuiTypography = (theme: Theme): Components['MuiTypography'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.grey[100],
      fontSize: 16,
    },
  },
  variants: [
    {
      props: { variant: 'h1' },
      style: {
        [theme.breakpoints.up('mobile')]: {
          fontSize: 30,
        },
        [theme.breakpoints.up('desktop')]: {
          fontSize: 35,
        },
      },
    },
    {
      props: { variant: 'h2' },
      style: {
        fontWeight: 600,
        [theme.breakpoints.up('mobile')]: {
          fontSize: 20,
        },
        [theme.breakpoints.up('desktop')]: {
          fontSize: 26,
        },
      },
    },
    {
      props: { variant: 'h5' },
      style: {
        [theme.breakpoints.up('mobile')]: {
          fontSize: 19,
        },
        [theme.breakpoints.up('desktop')]: {
          fontSize: 22,
        },
      },
    },
    {
      props: { variant: 'subtitle1' },
      style: {
        [theme.breakpoints.up('mobile')]: {
          fontSize: 14,
        },
        [theme.breakpoints.up('desktop')]: {
          fontSize: 16,
        },
      },
    },
  ],
});

export default makeMuiTypography;
