import { Box, Stack, Drawer } from '@mui/material';
import { useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import translations from '../../_i18n/navigationHeader.json';
import breedersLogoWithName from '../../assets/png/breedersLogoWithName.png';
import { IntlContext } from '../../providers/IntlProviderConfigured';
import HamburgerMenu from '../HamburgerMenu';
import LinksList from './components/LinksList';

const NavigationHeader = () => {
  const { language } = useContext(IntlContext);
  const [open, setOpen] = useState(false);
  return (
    <IntlProvider
      locale={language}
      key={language}
      messages={translations[language]}
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          zIndex: { mobile: 5, desktop: 2 },
          width: '100%',
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            bgcolor: 'rgba(0,0,0,.6)',
            height: { mobile: 60, desktop: 100 },
            position: 'relative',
            px: { mobile: 2, desktop: 9 },
            width: '100%',
          }}
        >
          <img src={breedersLogoWithName} style={{ width: 200 }} />
          <LinksList sx={{ display: { mobile: 'none', desktop: 'flex' } }} />
          <Box sx={{ width: 200 }} /> {/* Placeholder for spacing */}
          <HamburgerMenu open={open} onClick={() => setOpen(!open)} />
          <Drawer
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              display: { desktop: 'none' },
              zIndex: 4,
              '.MuiPaper-root': {
                bgcolor: 'rgba(0, 0, 0, 0.95)',
                pl: 5,
                pt: 12,
                width: '70%',
              },
            }}
          >
            <LinksList />
          </Drawer>
        </Stack>
      </Box>
    </IntlProvider>
  );
};

export default NavigationHeader;
