import { WaxJS } from '@waxio/waxjs/dist';
import { createContext, ReactChild, ReactChildren } from 'react';

const wax = new WaxJS({
  rpcEndpoint: `${process.env.REACT_APP_WAX_API}`,
  tryAutoLogin: true,
});

type ContextProps = {
  saveCredentialsInLocalStorage: () => void;
  wax: WaxJS;
};

export const WaxContext = createContext({} as ContextProps);

const WaxProviderContext = ({
  children,
}: {
  children: ReactChild | ReactChildren;
}) => {
  const saveCredentialsInLocalStorage = () => {
    localStorage.setItem('wax_userAccount', wax.userAccount);
    localStorage.setItem('wax_pubKeys', JSON.stringify(wax.pubKeys));
  };
  return (
    <WaxContext.Provider value={{ saveCredentialsInLocalStorage, wax }}>
      {children}
    </WaxContext.Provider>
  );
};

export default WaxProviderContext;
