import '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material';
import makeMuiAvatar from './factories/makeMuiAvatar';
import makeMuiButton from './factories/makeMuiButton';
import makeMuiCard from './factories/makeMuiCard';
import makeMuiCardContent from './factories/makeMuiCardContent';
import makeMuiCssBaseline from './factories/makeMuiCssBaseline';
import makeMuiLoadingButton from './factories/makeMuiLoadingButton';
import makeMuiMenu from './factories/makeMuiMenu';
import makeMuiMenuItem from './factories/makeMuiMenuItem';
import makeMuiSelect from './factories/makeMuiSelect';
import makeMuiSvgIcon from './factories/makeMuiSvgIcon';
import makeMuiTypography from './factories/makeMuiTypography';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    desktop: true;
  }
  interface Theme {
    shape: {
      borderRadius: number;
      sizes: { small: number; medium: number; large: number };
    };
  }
  interface ThemeOptions {
    shape: {
      borderRadius: number;
      sizes: { small: number; medium: number; large: number };
    };
  }
}

const theme = createTheme({
  breakpoints: { values: { mobile: 0, tablet: 640, desktop: 1024 } },
  typography: { fontFamily: 'Poppins' },
  palette: {
    error: { main: '#ff7474 ', dark: 'rgba(255, 0, 0, 0.15)', light: '#ff0000' },
    warning: { main: '#ea9a54', dark: 'rgba(255,162,0, .5)', light: '#ffb400' },
    primary: { main: '#1eff00', dark: '#084100' },
    secondary: { main: '#760878', dark: '#ff00fc', light: '#ff8800' },
    info: { main: '#015b80', dark: '#143b40' },
    success: { main: '#29c315', dark: '#194224' },
    grey: {
      600: '#000000',
      500: '#1a1a1a',
      400: '#282828',
      300: '#424143',
      200: '#666666',
      100: '#f1f1f1',
    },
  },
  shape: { borderRadius: 5, sizes: { small: 30, medium: 36, large: 44 } },
  spacing: [0, 4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 96, 108],
});

theme.components = {
  MuiAvatar: makeMuiAvatar(),
  MuiButton: makeMuiButton(theme),
  MuiCard: makeMuiCard(theme),
  MuiCardContent: makeMuiCardContent(theme),
  MuiCssBaseline: makeMuiCssBaseline(theme),
  MuiLoadingButton: makeMuiLoadingButton(theme),
  MuiMenu: makeMuiMenu(theme),
  MuiMenuItem: makeMuiMenuItem(theme),
  MuiSelect: makeMuiSelect(theme),
  MuiSvgIcon: makeMuiSvgIcon(theme),
  MuiTypography: makeMuiTypography(theme),
};

export default theme;
