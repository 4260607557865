import { Stack, SxProps, Theme, Typography } from '@mui/material';
import styles from './styles';

type CoinBalanceProps = {
  name: string;
  balance: number;
  thumbnail: string;
  sx?: SxProps<Theme>;
};

const CoinBalance = ({ name, balance, thumbnail, sx }: CoinBalanceProps) => (
  <Stack alignItems="center" direction="row" gap={3} sx={sx}>
    <img alt={name} src={thumbnail} style={styles.avatar} />
    <Typography variant="subtitle1" data-testid={`${name}Balance`}>
      {balance} {name}
    </Typography>
  </Stack>
);

export default CoinBalance;
