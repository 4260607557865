import { Alert } from '@mui/material';
import { AlertTemplateProps } from 'react-alert';

const AlertTemplate = ({
  message,
  close,
  style,
  options,
}: AlertTemplateProps) => (
  <Alert
    data-testid="alert"
    severity={options.type}
    style={style}
    onClose={close}
  >
    {message}
  </Alert>
);

export default AlertTemplate;
