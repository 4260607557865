import { Logout as LogoutIcon } from '@mui/icons-material';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { MouseEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import esbCoin from '../assets/png/esbCoin.png';
import waxCoin from '../assets/png/waxCoin.png';
import { CoinType } from '../types/Coin';
import { Context } from '../providers/Context';
import CoinBalance from './CoinBalance/CoinBalance';

const Header = () => {
  const { tokens } = useContext(Context);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userName = localStorage.getItem('wax_userAccount');
  const openMenuHandler = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenuHandler = () => setAnchorEl(null);
  const getBalanceByType = (type: CoinType) =>
    tokens?.find((token) => token.symbol === type)?.amount || 0;
  const logout = () => {
    localStorage.removeItem('wax_userAccount');
    localStorage.removeItem('wax_userPrivateKey');
    navigate('/');
  };
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{ mb: 6, mt: { mobile: 9, desktop: 11 } }}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap={{ mobile: 3, desktop: 4 }}
      >
        <CoinBalance
          name="WAX"
          balance={getBalanceByType(CoinType.WAX)}
          thumbnail={waxCoin}
        />
        <CoinBalance
          name="ESB"
          balance={getBalanceByType(CoinType.ESB)}
          thumbnail={esbCoin}
        />
      </Stack>
      <Stack alignItems="center" direction="row" gap={6}>
        <Button variant="text" data-testid="buttonToOpenMenu">
          <Typography variant="subtitle1" onClick={openMenuHandler}>
            {userName}
          </Typography>
        </Button>
       
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeMenuHandler}>
          <MenuItem onClick={logout} data-testid="itemToLogout">
            <Button variant="text" endIcon={<LogoutIcon fontSize="large" />}>
              <Typography variant="subtitle1">logoff</Typography>
            </Button>
          </MenuItem>
        </Menu>
      </Stack>
    </Stack>
  );
};

export default Header;
