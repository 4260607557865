const LANGUAGES = {
  pt: {
    urlLang: 'pt',
    code: 'pt-BR',
  },
  en: {
    urlLang: 'en',
    code: 'en-US',
  },
  default: 'en',
};

export default LANGUAGES;
