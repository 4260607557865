import { Components, Theme } from '@mui/material';

const makeMuiCardContent = (theme: Theme): Components['MuiCardContent'] => ({
  styleOverrides: {
    root: {
      padding: `${theme.spacing(4)} !important`,
    },
  },
});

export default makeMuiCardContent;
