import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { getSessionTime, removeSessionTime } from '../services/sessionTime';

const oneMinute = 60000;

const ControlSessionTime = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  useEffect(() => {
    setInterval(() => {
      const sessionTime = getSessionTime();
      if (sessionTime) {
        const passSessionTimeToNumber = Number(sessionTime);
        const passSessionTimeToDate = new Date(passSessionTimeToNumber);
        const sessiontTimeAfterOneHour = passSessionTimeToDate.setMinutes(
          passSessionTimeToDate.getMinutes() + 60,
        );
        const theSessionHasAlreadyExpired =
          new Date().getTime() > sessiontTimeAfterOneHour;
        if (theSessionHasAlreadyExpired) {
          removeSessionTime();
          alert.error('Your session has expired');
          localStorage.removeItem('wax_userAccount');
          localStorage.removeItem('wax_userPrivateKey');
          navigate('/');
        }
      }
    }, oneMinute);
  }, []);
  return null;
};

export default ControlSessionTime;
