/* eslint-disable @typescript-eslint/no-explicit-any */
import { DialogProps, IconButtonProps, SxProps, Theme } from '@mui/material';
import { ReactElement } from 'react';

export interface IDialogOptions {
  title?: string | ReactElement;
  message?: string;
  closable?: boolean;
  hideCloseIcon?: boolean;
  element?: ReactElement;
  iconButton?: IconButtonProps;
  type?: 'dialog' | 'confirmation';
  callback?: any;
  onOk?: () => void;
  onCancel?: () => void;
  dialogProps?: DialogProps;
  sx?: SxProps<Theme>;
}

export const DEFAULT_DIALOG_OPTIONS: IDialogOptions = {
  closable: true,
  hideCloseIcon: false,
};
