/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { DialogContext } from './IDialogContext';

export const useDialog = () => {
  const { openAsConfirmation, openAsDialog, close } = useContext(DialogContext);
  const confirm = (options: any): Promise<boolean> =>
    new Promise((callback) => openAsConfirmation({ callback, ...options }));
  // TODO: add typing
  const open = (options: any): Promise<any> =>
    new Promise((callback) => openAsDialog({ callback, ...options }));
  return { confirm, open, close };
};

export const useConfirmationDialog = () => useContext(DialogContext);
