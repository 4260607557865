import { Components, Theme } from '@mui/material';

const makeMuiSelect = (theme: Theme): Components['MuiSelect'] => ({
  defaultProps: { color: 'primary' },
  variants: [
    {
      props: { color: 'primary', variant: 'outlined' },
      style: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.grey[600],
        border: 'none',
        height: 39,
        width: '100%',
        '&.Mui-focused': {
          backgroundColor: theme.palette.grey[600],
          '&:before': {
            border: 'none',
          },
          '&:after': {
            border: 'none',
          },
        },
        '.MuiSvgIcon-root': {
          color: theme.palette.grey[100],
          height: '100%',
          backgroundColor: theme.palette.grey[500],
          borderRadius: 1,
          width: 40,
          right: 0,
          top: 0,
        },
      },
    },
  ],
});

export default makeMuiSelect;
