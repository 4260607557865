import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: ReactElement;
}

const isLoggedIn = () => !!localStorage.getItem('wax_userAccount');

const PrivateRoute = ({ children }: PrivateRouteProps) =>
  isLoggedIn() ? children : <Navigate to="/" />;

export default PrivateRoute;
