import { LoginResult } from 'anchor-link';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnchorContext } from '../components/AnchorProvider';

const ANCHOR_APP = 'Breeders';

const RestoreAnchorLogin = () => {
  const { anchor, setAnchorInstance } = useContext(AnchorContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isOnTheMarketPageOrBox =
    pathname.includes('/breeders/box') || pathname.includes('/breeders/market');
  useEffect(() => {
    const restoreSession = async () => {
      const platformType = localStorage.getItem('platformType') as string;
      const platformIsTypeAnchor = platformType === 'ANCHOR';
      if (platformIsTypeAnchor) {
        try {
          const thereIsSession = await anchor.restoreSession(ANCHOR_APP);
          if (thereIsSession) {
            setAnchorInstance(thereIsSession);
            localStorage.setItem(
              'wax_userAccount',
              `${thereIsSession.auth.actor}`,
            );
            return;
          }
          const loginResult: LoginResult = (await anchor.login(
            ANCHOR_APP,
          )) as LoginResult;
          setAnchorInstance(loginResult.session);
          localStorage.setItem('wax_userAccount', loginResult.payload.sa);
        } catch {
          navigate('/');
        }
      }
    };
    if (!isOnTheMarketPageOrBox) {
      restoreSession();
    }
  }, [pathname]);
  return null;
};

export default RestoreAnchorLogin;
